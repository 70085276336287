



























































































































































































































import { Component, Vue, Inject } from 'vue-property-decorator'
import { StakingViewModel } from '../viewmodels/staking-viewmodel'

@Component
export default class BoxInput extends Vue {
  @Inject() vm!: StakingViewModel
  focus = false
}
